export function setAccessToken(token: string): void {
  localStorage.setItem('access_token', token);
}

export function getAccessToken(): string | null {
  return localStorage.getItem('access_token');
}

export function setRefreshToken(token: string): void {
  localStorage.setItem('refresh_token', token);
}

export function getRefreshToken(): string | null {
  return localStorage.getItem('refresh_token');
}

export function wipeToken(): void {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

export function getTokenMillisecondsExp(token: string): number {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  return exp * 1000;
}
