export enum Language {
  EN = 'en',
  NL = 'nl',
}

export type AuthTokens = {
  /**
   * The access token
   */
  accessToken: string;
  /**
   * The refresh token
   */
  refreshToken: string;
}

export interface Frame { startDate: Date, endDate: Date }
