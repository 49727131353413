import httpService from './httpService';
import { Reservation } from '../types/reservations';

export async function getReservations(): Promise<Reservation[]> {
  return httpService.get<Reservation[]>('/api/me/reservations')
    .then((res) => res.data);
}

export async function downloadPdfReservationById(reservationIds: number[]): Promise<Buffer> {
  const params = reservationIds.map(id => `reservationId=${id}`).join('&');
  return httpService.post<Buffer>(`/api/reservations/download/me?${params}`, {}, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
    .then((res) => res.data);
}
