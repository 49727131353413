import React, { HTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { GiConfirmed } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import RoundedCard from '../../cards/RoundedCard';
import useAvailableSubscriptionsExtension from '../../../hooks/selectors/useAvailableSubscriptionsExtension';
import useSubscriptionExtensionState from '../../../hooks/selectors/useSubscriptionExtensionState';
import { AvailableSubscriptionExtension } from '../../../types/subscriptions';
import SubscriptionTypeCard from '../../cards/extension/SubscriptionTypeCard';
import { IconButton } from '../../buttons/IconButton';
import { PaymentType } from '../../../types/payment';

export function SubscriptionTypeExtensionWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const availableSubscriptions = useAvailableSubscriptionsExtension();
  const [availableSubscription, setAvailableSubscription] = useState<AvailableSubscriptionExtension | undefined>(undefined);
  const { t } = useTranslation('subscription_type_extension_widget');
  const { subscriptionId, extensionTypeId } = useSubscriptionExtensionState();
  const navigate = useNavigate();

  const handleOnClickSubscriptionCard = (): void => {
    navigate(`/payment?paymentType=${PaymentType.SUBSCRIPTION_EXTENSION}`);
  };

  useEffect(() => {
    setAvailableSubscription(availableSubscriptions.find(s => s.subscription.id === subscriptionId))
  }, [subscriptionId]);

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        <div>
          <h2 className="text-xl font-bold py-2">{t('extend_type')}</h2>
          <div className="flex flex-col gap-1">
            {
              availableSubscription &&
              availableSubscription.subscriptionTypes.map(type => <SubscriptionTypeCard subscriptionType={type} />)
            }
          </div>
        </div>
        {
          !!extensionTypeId &&
          <IconButton icon={GiConfirmed} label={t("pay")} onClick={handleOnClickSubscriptionCard}
                      className="text-center mt-3 bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
        }
      </div>
    </RoundedCard>
  );
}
