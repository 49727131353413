import React, { InputHTMLAttributes } from 'react';

type TextInputProps = {
  setChecked?: (checked: boolean) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export default function CheckboxInput({
  className,
  onChange,
  ...props
}: TextInputProps): JSX.Element {
  return (
    <input
      {...props}
      type="checkbox"
      className="text-black h-5 w-5 border-2 border-black rounded-full focus:ring-0 focus:ring-transparent"
      onChange={onChange ?? ((e): void => props.setChecked?.(e.target.checked))}
    />
  );
}
