import React from 'react';
import { Purchase } from '../../types/payment';
import PurchaseTable from '../tables/PurchaseTable';
import { RotterdampasTable } from '../tables/RotterdampasTable';

type PurchaseCardProps = {
  purchases: Purchase[];
  bookingFee: number;
  timeZone: string;
}

export default function TicketPurchaseCard({ purchases, bookingFee, timeZone }: PurchaseCardProps): JSX.Element {
  return (
    <div className="flex flex-col gap-1">
      <div className="relative overflow-x-auto">
        <PurchaseTable purchases={purchases} bookingFee={bookingFee} timeZone={timeZone} />
        <RotterdampasTable purchases={purchases} />
      </div>
    </div>
  );
}