import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Price from "../misc/Price";
import BooleanIcon from "../icons/BooleanIcon";
import { Purchase } from "../../types/payment";
import useReservations from '../../hooks/selectors/useReservations';

type PurchaseTableProps = {
  purchases: Purchase[];
  bookingFee: number;
  timeZone: string
};

export default function PurchaseTable({ purchases, bookingFee, timeZone }: PurchaseTableProps) : JSX.Element {
  const { t } = useTranslation("purchase_table");
  const reservations = useReservations();


  function renderDate(date: Date) : string {
    return new Date(date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone});
  }

  function calculateTotalAmount(purchasesToCalculate: Purchase[], bookingFeeToCalculate: number) : JSX.Element {
    const totalAmount = purchasesToCalculate.reduce((total, currentValue) => total + currentValue.price, 0) + bookingFeeToCalculate;
    return <Price price={totalAmount} />;
  }

  const renderPurchases = purchases.map((purchase) => {
    const reservation = reservations.find(d => d.id === purchase.extendedPurchaseData.reservation?.id);
    const reservationScannedSuccesful = (reservation?.scanHistories?.filter(d => d.succesful).length ?? 0) > 0;

    const renderTimeslot = reservation?.ticketTypeOnSlot.slot.startTime && !reservation?.ticketTypeOnSlot.ticketType.alwaysAvailable ? `(${renderDate(new Date(reservation.ticketTypeOnSlot.slot.startTime))})` : null;

    return (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        {
          purchase.extendedPurchaseData.reservation &&
          <td className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {`${purchase.extendedPurchaseData.reservation?.ticketTypeOnSlot.ticketType.name}`} {renderTimeslot} <br/>
            <span className='text-xs text-gray-500'>{purchase.extendedPurchaseData.reservation?.qrCode.code}</span><br/>
          </td>
        }
        { purchase.extendedPurchaseData.subscription && <td className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {`${purchase.extendedPurchaseData.subscription.subscriptionType.name}`}
        </td> }
        <td className="px-1 py-1">
          {reservation?.status === "CONFIRMED" && <BooleanIcon value={reservationScannedSuccesful} label={reservationScannedSuccesful ? t("used") : t("active")} />}
          {reservation?.status !== "CONFIRMED" && <BooleanIcon value label={t("inactive")} />}
        </td>
        <td className="px-1 py-1">
          {t(purchase.type)}
        </td>
        <td className="px-1 py-1">
          <Price price={purchase.price}/>
        </td>
        <td className="px-1 py-1">
          { purchase.refundPaymentId &&
            <Link to={`/payments/details/${purchase.refundPaymentId}`} className="text-blue-600 underline hover:text-blue-800">{`${t('refund_link')} #${purchase.refundPaymentId}`}</Link>
          }
        </td>
      </tr>
    )
  });

  return (
    <>
      <div className="border-2 rounded-3xl p-4 mt-2 mb-4 overflow-x-scroll no-scrollbar">
        <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400">
          <thead className="text-xs text-black-700 uppercase dark:text-black-400 border-b dark:border-gray-700">
          <tr>
            <th scope="col" className="py-3">
              {t("product")}
            </th>
            <th scope="col" className="py-3">
              {t("status")}
            </th>
            <th scope="col" className="py-3">
              {t("type")}
            </th>
            <th scope="col" className="py-3">
              {t("price")}
            </th>
          </tr>
          </thead>
          <tbody>
          {renderPurchases}
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td className='px-1 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white'>{t("booking_fee")}</td>
            <td className="px-1 py-3" />
            <td className="px-1 py-3" />
            <td className="px-1 py-3"><Price price={bookingFee}/></td>
          </tr>
          <tr>
            <td className='px-1 py-3 text-gray-900 whitespace-nowrap dark:text-white font-gintobold font-bold'>{t("total")}</td>
            <td />
            <td />
            <td className='px-1 py-3 font-gintobold font-bold'>{calculateTotalAmount(purchases, bookingFee)}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
