import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SubscriptionsWidget } from '../../components/widgets/subscriptionpage/SubscriptionsWidget';
import {
  SubscriptionBookingCalendarWidget
} from '../../components/widgets/subscriptionpage/SubscriptionBookingCalendarWidget';
import useSubscriptionBookingState from '../../hooks/selectors/useSubscriptionBookingState';
import { SubscriptionBookingSlotWidget } from '../../components/widgets/subscriptionpage/SubscriptionBookingSlotWidget';
import { setSubscriptionBooking } from '../../redux/slices/subscriptionBookingSlice';

export function SubscriptionBookPage(): JSX.Element {
  const booking = useSubscriptionBookingState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSubscriptionBooking({ subscriptionId: undefined, day: new Date(), cardholderIds: [] }));
  }, []);

  return (<main className="grid gap-4 grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
    <SubscriptionsWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    {
      !!booking.subscriptionId && booking.cardholderIds.length > 0 &&
      <SubscriptionBookingCalendarWidget className="col-span-1 max-sm:col-span-1 min-h-52"/>
    }
    {
      !!booking.subscriptionId && booking.cardholderIds.length > 0 &&
      <SubscriptionBookingSlotWidget className="col-span-1 max-sm:col-span-1 min-h-52"/>
    }
  </main>)
}