import React, { HTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import RoundedCard from '../../cards/RoundedCard';
import usePayments from '../../../hooks/selectors/usePayments';
import useSelectedPayment from '../../../hooks/selectors/useSelectedPayment';
import { Payment, ShopType } from '../../../types/payment';
import TicketPurchaseCard from '../../cards/TicketPurchaseCard';
import SubscriptionPurchaseCard from '../../cards/SubscriptionPurchaseCard';

export function PurchaseWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const payments = usePayments();
  const paymentId = useSelectedPayment();
  const { t } = useTranslation('purchase_widget');
  const [payment, setPayment] = useState<Payment | undefined>(undefined);

  useEffect(() => {
    setPayment(payments.find(p => p.id === paymentId));
  }, [paymentId]);

  if (!payment) {
    return (<></>);
  }

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        <div>
          <h2 className="text-xl font-bold py-2">{t('purchases')}</h2>
          <div className="flex flex-col gap-1">
            {
              payment.extendedPaymentData.shop.type === ShopType.TICKET
              ? <TicketPurchaseCard purchases={payment.purchases} bookingFee={payment.bookingFee} timeZone={payment.location.timezone}/>
              : <SubscriptionPurchaseCard purchases={payment.purchases} bookingFee={payment.bookingFee} timeZone={payment.location.timezone} />
            }
          </div>
        </div>
      </div>
    </RoundedCard>
  );
}
