export function validatePassword(password: string | undefined | null): boolean {
  return password !== undefined
    && password !== null
    && password.length >= 8
    && password.length <= 36
    && /[A-Z]/.test(password)
    && /[a-z]/.test(password)
    && /[0-9]/.test(password)
    && /[!@#$%^&*(),.?":{}|<>]/.test(password);
}
