import React, { ImgHTMLAttributes } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export type LogoProps = { variant?: 'black' | 'white' } & ImgHTMLAttributes<HTMLImageElement>;

export default function Logo({ variant = 'black', ...props }: LogoProps): JSX.Element {
  const { width } = useWindowDimensions();


  return (
    <img
      {...props}
      src={`${process.env.PUBLIC_URL}/assets/logo/sbr_logo_${variant}${width < 768 ? '_mobile' : ''}.svg`}
      alt="SB logo"
    />
  );
}
