import React from "react";
import { useTranslation } from "react-i18next";
import { Purchase } from "../../types/payment";

type RotterdampasTableProps = {
  purchases: Purchase[]
}

export function RotterdampasTable({ purchases } : RotterdampasTableProps) : JSX.Element | null {
  const { t } = useTranslation("purchase_table");

  const tableRows = purchases
    .filter(data => data.extendedPurchaseData.rotterdampasses && data.extendedPurchaseData.rotterdampasses.length > 0)
    .flatMap(purchase =>
      purchase.extendedPurchaseData.rotterdampasses.map(rotterdampas => (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td className="flex px-1 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <img alt="Rotterdampas" src={`${process.env.PUBLIC_URL}/assets/rotterdampas/rotterdampasgeel.svg`} className="w-6 h-6 mr-4" />
            {rotterdampas.cardNumber}
          </td>
          <td className="px-1 py-1">
            {rotterdampas.cardStatus}
          </td>
        </tr>
      ))
    );

  if (tableRows.length === 0) {
    return null;
  }

  return (
    <>
      <h3 className='font-gintobold font-bold text-l mb-4'>{t("rotterdampasses")}</h3>
      <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400">
        <thead className="text-xs text-black-700 uppercase dark:text-black-400 border-b dark:border-gray-700">
        <tr>
          <th scope="col" className="py-3">
            {t("card")}
          </th>
          <th scope="col" className="py-3">
            {t("status")}
          </th>
        </tr>
        </thead>
        <tbody>
        {tableRows}
        </tbody>
      </table>
    </>
  );


}