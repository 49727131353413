import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RoundedCard from "./RoundedCard";
import useSelectedPayment from '../../hooks/selectors/useSelectedPayment';
import usePayments from '../../hooks/selectors/usePayments';
import { setSelectedPayment } from '../../redux/slices/selectedPaymentSlice';

type PaymentCardProps = {
  id: number;
}

export default function PaymentCard({ id }: PaymentCardProps): JSX.Element {
  const dispatch = useDispatch();
  const paymentId = useSelectedPayment();
  const  payment = usePayments().find(p => p.id === id);
  const { t } = useTranslation("payment_card");
  if (!payment) {
    return (<></>);
  }
  const handleOnClickSubscriptionCard = (): void => {
    if (paymentId === id) {
      return;
    }

    dispatch(setSelectedPayment(id));
  }

  return (
    <RoundedCard
      onClick={handleOnClickSubscriptionCard}
      className={`flex justify-between items-center ${paymentId === id ? `bg-sb-dark-blue` : `bg-sb-purple`} 
      text-white rounded-sb-sm bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500 
      hover:cursor-pointer`}>
      <div>
        {
          paymentId !== payment.id &&
          <p className="font-bold">{t("id")}: {payment.id}</p>
        }

        {
          paymentId === payment.id &&
          <p>
            <div className='mb-4'>{t("id")}:  {payment.id}<br /></div>
            <div className='mb-4'>{t("location")}: {payment.extendedPaymentData.location.name}<br /></div>
            <div
              className='mb-4'>{t("date")}: {new Date(payment.date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}
            </div>
            {
              payment.paymentReference &&
              <div className="mb-4">{t('payment_reference')}: {payment.paymentReference}</div>
            }
            {
              payment.method &&
              <div className="mb-4">{t('payment_method')}: {payment.method}</div>
            }
            {payment.discounts.length > 0 && <div className='mb-4'>{t("discount_codes")}: <br />
              {
                payment.discounts.map((discount) => {
                  return <>{discount.code} <br /></>;
                })
              }
            </div>}
          </p>
        }
      </div>
    </RoundedCard>
  );
}