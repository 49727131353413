import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TicketsWidget } from '../components/widgets/homepage/TicketsWidget';
import { ProgrammeWidget } from '../components/widgets/homepage/ProgrammeWidget';
import { IceQualityWidget } from '../components/widgets/homepage/IceQualityWidget';
import { RebookWidget } from '../components/widgets/homepage/RebookWidget';
import { ScanHistoryWidget } from '../components/widgets/homepage/ScanHistoryWidget';
import { NewsWidget } from '../components/widgets/homepage/NewsWidget';
import { PaymentHistoryWidget } from '../components/widgets/homepage/PaymentHistoryWidget';
import useAuth from '../hooks/selectors/useAuth';

export default function LandingPage(): JSX.Element {
  const auth = useAuth();

  const navigate = useNavigate();
  if (!auth) {
    navigate('login');
    return <></>;
  }

  return <AuthorisedLayout />;
}

function AuthorisedLayout(): JSX.Element {
  return (
    <main className="grid gap-4 grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1">
      <TicketsWidget className="col-span-2 max-sm:col-span-1 min-h-52" />
      <ProgrammeWidget className="min-h-52" />
      <IceQualityWidget className="min-h-52" />
      <RebookWidget className="min-h-52" />
      <ScanHistoryWidget className="min-h-52" />
      <NewsWidget className="min-h-52" />
      <PaymentHistoryWidget className="min-h-52" />
    </main>
  );
}

