import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import LabeledTextInput from '../../inputs/LabeledTextInput';
import Button from '../../buttons/Button';
import { validatePassword } from '../../../utils/passwordUtil';

type ResetPasswordFormProps = {
  onSubmit: (newPassword: string) => void;
} & Omit<ComponentProps<'form'>, 'onSubmit'>;

export default function ResetPasswordForm({ onSubmit, className, ...props }: ResetPasswordFormProps): JSX.Element {
  const { t } = useTranslation('reset_password_forms');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [passwordMismatch, setPasswordMismatch] = React.useState<boolean>();
  const [isPasswordWeak, setIsPasswordWeak] = React.useState<boolean>(false);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>): void => {
    ev.preventDefault();
    if (isPasswordWeak) {
      toast.error(t('password_mismatch', { toastId: 'password_mismatch' }));
    } else if (password !== confirmPassword) {
      toast.error(t('password_not_strong_enough', { toastId: 'password_not_strong_enough' }));
    } else {
      onSubmit(password);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={twMerge('flex flex-col gap-4', className)} {...props}>
      <div>
        <LabeledTextInput
          type="password" name="password" id="password" label={`${t('password_label').toLowerCase()}: `}
          value={password} placeholder={t('password_placeholder')}
          onChange={(ev) => {
            setPassword(ev.target.value);
            setIsPasswordWeak(!validatePassword(ev.target.value));
          }}
          onBlur={() => passwordMismatch !== undefined && setPasswordMismatch(password !== confirmPassword)}
        />
        <p className={twMerge('text-sm ml-2 my-0.5', classNames({
          'text-gray-500': !isPasswordWeak,
          'text-sb-pink': isPasswordWeak,
        }))}>{t('password_helper')}</p>
      </div>
      <div>
        <LabeledTextInput
          className={classNames({ 'bg-sb-light-pink': passwordMismatch })}
          type="password" name="repeatPassword" id="repeat-password"
          label={`${t('repeat_password_label').toLowerCase()}: `}
          value={confirmPassword} placeholder={t('repeat_password_placeholder')}
          onChange={(ev) => {
            setConfirmPassword(ev.target.value);
            setPasswordMismatch(password !== ev.target.value);
          }}
        />
        <p
          className={`text-sm ml-2 mt-0.5 text-sb-pink ${passwordMismatch ? 'visible' : 'invisible'}`}>{t('password_mismatch')}</p>
      </div>
      <Button type="submit"
              className="w-fit self-end bg-sb-pink font-semibold px-12 py-4 rounded-full max-md:w-full">{t('confirm_button')}</Button>
    </form>
  );
}
