import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import LoginForm from '../../components/forms/auth/LoginForm';
import RoundedCard from '../../components/cards/RoundedCard';
import { UsernamePasswordDto } from '../../types/accounts';
import { login } from '../../services/authService';

export default function LoginPage(): JSX.Element {
  const { t } = useTranslation('auth');

  const onSubmit = (loginDto: UsernamePasswordDto): void => {
    login(loginDto)
      .then(() => toast.success(t('login_success', { toastId: 'login_success' })))
      .catch((error: AxiosError) => {
        if (error.response?.status === 401 || error.response?.status === 400) {
          toast.error(t('wrong_credentials', { toastId: 'login_failed' }));
        } else {
          toast.error(t('login_failed', { toastId: 'login_failed' }));
        }
      });
  };

  return (
    <RoundedCard variant="centered-form">
      <h2 className="text-2xl font-bold text-center">{t('welcome_back_title')}</h2>
      <LoginForm onSubmit={onSubmit} />
    </RoundedCard>
  );
}
