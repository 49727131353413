import React, { ChangeEvent, ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LabeledTextInput from '../../inputs/LabeledTextInput';
import { UsernamePasswordDto } from '../../../types/accounts';
import Button from '../../buttons/Button';

type LoginFormProps = {
  onSubmit: (loginDto: UsernamePasswordDto) => void;
} & Omit<ComponentProps<'form'>, 'onSubmit'>;

export default function LoginForm({ onSubmit, className, ...props }: LoginFormProps): JSX.Element {
  const { t } = useTranslation('auth');
  const [loginDto, setLoginDto] = React.useState<UsernamePasswordDto>({} as UsernamePasswordDto);

  const handleChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    setLoginDto((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  };

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>): void => {
    ev.preventDefault();
    onSubmit(loginDto);
  };

  return (
    <form onSubmit={handleSubmit} className={twMerge('flex flex-col gap-4', className)} {...props}>
      <LabeledTextInput
        type="email" name="email" id="email" label={`${t('email_label').toLowerCase()}: `}
        value={loginDto.email} placeholder={t('email_placeholder')} onChange={handleChange}
      />
      <div className="flex flex-col gap-2">
        <LabeledTextInput
          type="password" name="password" id="password" label={`${t('password_label').toLowerCase()}: `}
          value={loginDto.password} placeholder={t('password_placeholder')} onChange={handleChange}
        />
        <Link className="underline text-sb-purple text-right mr-2 mb-2" to="/forgotten-password">{t('reset_password_link')}</Link>
      </div>
      <Button type="submit" variant="pink-primary">{t('login_button')}</Button>
    </form>
  );
}
