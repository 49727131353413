import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionSelectionState } from '../../types/redux';

const initialState: SubscriptionSelectionState = {
  subscriptionId: undefined,
};

const selectedSubscriptionSlice = createSlice({
  name: 'selectedSubscription',
  initialState,
  reducers: {
    setSelectedSubscription(state, action: PayloadAction<number | undefined>): void {
      state.subscriptionId = action.payload;
    },
  },
});

const selectedSubscriptionReducer = selectedSubscriptionSlice.reducer;

export const { setSelectedSubscription } = selectedSubscriptionSlice.actions;
export default selectedSubscriptionReducer;
