import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlinePayments } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import RoundedCard from '../../cards/RoundedCard';
import { IconButton } from '../../buttons/IconButton';

export function PaymentHistoryWidget(props: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const { t } = useTranslation('payment_history_widget')
  const navigate = useNavigate();
  return (
    <RoundedCard {...props} className="flex-col flex items-center">
      <h1>{t("payment_history")}</h1>
      <IconButton icon={MdOutlinePayments} label={t("see_history")} onClick={() => {navigate('/payments')}}
                  className="h-fit w-full text-center mt-3 bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
    </RoundedCard>
  );
}
