import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { SubscriptionViewWidget } from '../../components/widgets/subscriptionpage/SubscriptionViewWidget';
import { setSelectedSubscription } from '../../redux/slices/selectedSubscriptionSlice';
import useSelectedSubscription from '../../hooks/selectors/useSelectedSubscription';
import { CardholderViewWidget } from '../../components/widgets/subscriptionpage/CardholderViewWidget';

export function SubscriptionListPage(): JSX.Element {
  const dispatch = useDispatch();
  const subscriptionId = useSelectedSubscription();

  useEffect(() => {
    dispatch(setSelectedSubscription(undefined));
  }, []);

  return (<main className="grid gap-4 grid-cols-2 max-lg:grid-cols-2 max-sm:grid-cols-1">
    <SubscriptionViewWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    {
      subscriptionId &&
      <CardholderViewWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    }
  </main>)
}
