import React, { HTMLAttributes, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import RoundedCard from '../../cards/RoundedCard';
import { getReservations } from '../../../services/reservationService';
import { setReservations } from '../../../redux/slices/reservationsSlice';
import useReservations from '../../../hooks/selectors/useReservations';
import TicketCard from '../../cards/TicketCard';

export function TicketsViewWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const dispatch = useDispatch();
  const reservations = useReservations();
  const { t } = useTranslation('tickets_view_widget');

  useEffect(() => {
    getReservations()
      .then((rvs) => dispatch(setReservations(rvs)))
      .catch(() => toast.error(t('reservations_error')));
  }, []);

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        <div>
          <h2 className="text-xl font-bold py-2">{t('reservations')}</h2>
          <div className="flex flex-col gap-1">
            {reservations.map((reservation) => <TicketCard reservation={reservation} />)}
          </div>
        </div>
      </div>
    </RoundedCard>
  );
}
