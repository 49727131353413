import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReservationsState } from '../../types/redux';
import { Reservation } from '../../types/reservations';

const initialState: ReservationsState = {
  reservations: [],
};

const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    setReservations(state, action: PayloadAction<Reservation[]>): void {
      state.reservations = action.payload;
    },
  },
});

const reservationsReducer = reservationsSlice.reducer;

export const { setReservations } = reservationsSlice.actions;
export default reservationsReducer;
