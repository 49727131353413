import {
  addDays,
  addMonths,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  isBefore, isSameDay,
  startOfMonth,
} from 'date-fns';
import { DateTime } from 'luxon';
import { Slot } from '../types/reservations';
import { AvailableDay } from '../types/subscriptions';

export function getDateString(date: Date | string): string {
  let d;
  if (typeof date === 'string') {
    d = new Date(date);
  } else {
    d = date;
  }

  return d.toLocaleDateString(); // TODO implement
}

export function getTimeString(date: Date | string): string {
  let d;
  if (typeof date === 'string') {
    d = new Date(date);
  } else {
    d = date;
  }

  return d.toLocaleTimeString(undefined, { timeStyle: "short" }); // TODO implement
}

export function getTimespanString(slot: Slot): string {
  return `${getTimeString(slot.startTime)} - ${getTimeString(slot.endTime)}`;
}

export function absoluteMonthDifference(a: Date | string, b: Date): number {
  const d = typeof a === 'string' ? new Date(a) : a;
  return d.getMonth() - b.getMonth() + 12 * (d.getFullYear() - b.getFullYear());
}

export function makeTimeIrrelevantDate(date: Date): Date {
  return DateTime.fromJSDate(date)
    .setZone('utc')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
}

/**
 * Returns an array of weeks in the month of the given index.
 *
 */
export function getMonthWeeks(index: number = 0): Date[][] {
  const targetMonth = addMonths(makeTimeIrrelevantDate(new Date()), index);
  const start = startOfMonth(targetMonth);
  const end = endOfMonth(targetMonth);

  return eachWeekOfInterval({ start, end }, { weekStartsOn: 1 }).map((weekStart) => {
    return eachDayOfInterval({ start: weekStart, end: addDays(weekStart, 6) });
  });
}

export function dateMax(a: Date, b: Date): Date {
  return a > b ? a : b;
}

export function isBeforeDay(a: Date, b: Date): boolean {
  return isBefore(a, b) && !isSameDay(a, b);
}

export function isWithinDaySpan(day: Date | string, availableDay: AvailableDay): boolean {
  const d = typeof day === 'string' ? new Date(day) : day;
  const availableDayDate = new Date(availableDay.date);
  const diff = availableDayDate.getTime() - d.getTime();
  return diff >= 0 && diff <= 24 * 60 * 60 * 1000;
}
/**
 * Parse a date to YYYY-MM-DD, HH:mm format from date and time zone
 *
 * @param date the date
 * @param timeZone the timezone
 */
export function parseToYYYYMMDDHHmm(date: Date, timeZone: string): string {
  return new Date(date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone});
}

/**
 * Parse a date to DD-MM-YYYY format from date and time zone
 *
 * @param date the date
 * @param timeZone the timezone
 */
export function parseToDDMMYYYY(date: Date | undefined | string, timeZone: string): string {
  if (!date) return '';
  return new Date(date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', timeZone});
}