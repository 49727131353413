import React, { HTMLAttributes, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import useSubscriptions from '../../../hooks/selectors/useSubscriptions';
import RoundedCard from '../../cards/RoundedCard';
import { downloadQrCodesCardholders } from '../../../services/subscriptionService';
import useSelectedSubscription from '../../../hooks/selectors/useSelectedSubscription';
import { Subscription } from '../../../types/subscriptions';
import { API_URL } from '../../../constants';
import useDateFormatSubscriptionList from '../../../hooks/useDateFormatSubscriptionList';
import BooleanIcon from '../../icons/BooleanIcon';
import Button from '../../buttons/Button';

export function CardholderViewWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const { t } = useTranslation("cardholder_view_widget");
  const subscriptionId = useSelectedSubscription();
  const subscriptions = useSubscriptions();
  const [subscription, setSubscription] = React.useState<Subscription | undefined>(undefined);
  const format = useDateFormatSubscriptionList();

  useEffect(() => {
    setSubscription(subscriptions.find(s => s.id === subscriptionId));
  }, [subscriptionId]);
  if (!subscription) {
    return <></>
  }

  const downloadCardholder = async (cardholdersUuid: string): Promise<void> => {
    const response = await downloadQrCodesCardholders([cardholdersUuid]);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${cardholdersUuid}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        {subscription.cardholders.map(cardholder => (
          <div className="border p-4 rounded-3xl mb-2 bg-sbr-lightblue">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-1">
                <img className="rounded-xl" width={100}
                     src={cardholder.identity.picture?.picturePath ? `${API_URL}/${cardholder.identity.picture.picturePath}` : `${process.env.PUBLIC_URL}/assets/subscriptions/user.jpeg`}
                     alt="user" />
              </div>
              <div className="col-span-2">
                {t("name")}: {`${cardholder.identity.firstName} ${cardholder.identity.lastName}`} <br />
                {t("birthdate")}: {cardholder.identity.birthdate ? format(cardholder.identity.birthdate!, 'dd-MM-yyyy') : ''}
                <br />
                {t("qrcode")}: {cardholder.qrCodeValue} <br />
                <BooleanIcon value={cardholder.disabled} label={!cardholder.disabled ? t("active") : t("blocked")} />
                <div className="flex justify-end">
                  <Button type="button" className="bg-sb-pink mb-4 mt-5 text-sm"
                          onClick={(): Promise<void> => downloadCardholder(cardholder.qrCodeValue!)}>
                    {t("download_cardholder")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </RoundedCard>
);
}
