import React, { HTMLAttributes, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import RoundedCard from '../../cards/RoundedCard';
import { setReservations } from '../../../redux/slices/reservationsSlice';
import useReservations from '../../../hooks/selectors/useReservations';
import {
  downloadQrCodesCardholders,
  getAvailableSubscriptionExtension,
  getSubscriptions,
} from '../../../services/subscriptionService';
import { setSubscriptions } from '../../../redux/slices/subscriptionsSlice';
import useSubscriptions from '../../../hooks/selectors/useSubscriptions';
import { downloadPdfReservationById, getReservations } from '../../../services/reservationService';
import TicketCard from '../../cards/TicketCard';
import ActiveSubscriptionCard from '../../cards/ActiveSubscriptionCard';
import SubscriptionCTA from '../../cta/SubscriptionCTA';
import Button from '../../buttons/Button';
import { setAvailableSubscriptionExtensions } from '../../../redux/slices/availableSubscriptionExtensionsSlice';
import useAvailableSubscriptionsExtension from '../../../hooks/selectors/useAvailableSubscriptionsExtension';
import { getPayments } from '../../../services/paymentService';
import { setPayments } from '../../../redux/slices/paymentSlice';
import usePayments from '../../../hooks/selectors/usePayments';
import { PaymentStatus, ShopType } from '../../../types/payment';
import { notFalsy } from '../../../utils/typeUtil';

export function TicketsWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const dispatch = useDispatch();
  const reservations = useReservations();
  const subscriptions = useSubscriptions();
  const payments = usePayments();
  const availableSubscriptionExtensions = useAvailableSubscriptionsExtension();
  const { t } = useTranslation('tickets_widget');

  useEffect(() => {
    getReservations()
      .then((rvs) => dispatch(setReservations(rvs)))
      .catch(() => toast.error(t('reservations_error')));

    getSubscriptions()
      .then((sbs) => dispatch(setSubscriptions(sbs)))
      .catch(() => toast.error(t('subscriptions_error')));

    getAvailableSubscriptionExtension()
      .then(avaSub => dispatch(setAvailableSubscriptionExtensions(avaSub)))
      .catch(() => toast.error(t('available_subscriptions_error')))

    getPayments()
      .then(res => dispatch(setPayments(res)))
      .catch(() => toast.error(t('payments_error')))
  }, []);

  const handleDownload = () => {
    const paymentsForDownload = payments.filter(p => p.status === PaymentStatus.PAID)
    if (paymentsForDownload.length === 0) {
      toast.error(t('no_payments'));
      return;
    }
    const payment = paymentsForDownload[0];
    if (payment.extendedPaymentData.shop.type === ShopType.TICKET) {
      const ids = payment.purchases.map(p => p.extendedPurchaseData.reservation?.id).filter(notFalsy);
      downloadPdfReservationById(ids)
        .then((pdfBuffer) => {
          const url = URL.createObjectURL(new Blob([pdfBuffer]));
          const a = document.createElement('a');
          a.href = url;
          a.download = `tickets-${payment.id}.pdf`;
          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch(() => toast.error(t('download_error')));
    } else {
      const ids = payment.purchases
        .flatMap(p => p.extendedPurchaseData.subscription?.cardholders.map(c => c.qrCodeValue))
        .filter(notFalsy);

      downloadQrCodesCardholders(ids)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `subscription-${payment.id}.pdf`);
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch(() => toast.error(t('download_error')));;
    }
  }

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex gap-2 max-sm:flex-col max-sm:items-center">
        <div className="flex flex-col gap-1 max-sm:w-full">
          {subscriptions.length > 0 ? <ActiveSubscriptionCard subscription={subscriptions[0]} /> : <SubscriptionCTA />}
          {subscriptions.length > 0 && (
            <Link className="underline text-sb-dark-purple text-center"
                  to="/subscriptions">{t('see_all_subscriptions')}</Link>
          )}
          {subscriptions.filter(s => !s.subscriptionType.alwaysAvailable).length > 0 && (
            <Link className="underline text-sb-dark-purple text-center"
                  to="/subscriptions-booking">{t('book_slot_for_subscription')}</Link>
          )}
          {availableSubscriptionExtensions.length > 0 && (
            <Link className="underline text-sb-dark-purple text-center"
                  to="/subscriptions-extend">{t('see_rebookable_subscription')}</Link>
          )}
        </div>
        <div className="flex-1 flex flex-col justify-between max-sm:w-full">
          <div>
            <h2 className="text-xl font-bold py-2">{t('my_tickets')}</h2>
            <div className="flex flex-col gap-1">
              {reservations.slice(0, 2).map((reservation) => <TicketCard reservation={reservation} />)}
              {reservations.length === 0 && t('no_tickets')}
              {reservations.length > 2 &&
                <Link className="text-blue-500 text-center underline" to="/tickets">{t('view_all_tickets')}</Link>}
            </div>
          </div>
          {
            payments.filter(p => p.status === PaymentStatus.PAID).length > 0 &&
            <Button className="justify-self-end whitespace-normal mt-1" onClick={handleDownload}>{t('download_all_tickets_from_previous_purchase')}</Button>}
        </div>
      </div>
    </RoundedCard>
  );
}
