import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../buttons/Button';
import RoundedCard from '../cards/RoundedCard';

export default function SubscriptionCTA(): JSX.Element {
  const { t } = useTranslation('tickets_widget');
  return (
    <RoundedCard className="bg-black w-52 h-full flex flex-col justify-between max-sm:w-full">
      <p className="text-center text-white font-semibold my-2">{t('no_subscription')}</p>
      <Link to="/#">
        <Button
          className="bg-gradient-to-r w-full text-xl from-sb-pink via-sb-purple to-amber-500 text-white rounded-sb-sm hover:shadow-[0_0_1.5rem_1px_white] bg-[size:_200%] text-wrap font-extrabold animate-move-bg">
          {t('subscribe_now')}
        </Button>
      </Link>
    </RoundedCard>
  );
}
