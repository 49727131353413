import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableSubscriptionExtensionsState } from '../../types/redux';
import { AvailableSubscriptionExtension } from '../../types/subscriptions';

const initialState: AvailableSubscriptionExtensionsState = {
  availableSubscriptionExtensions: []
}

const availableSubscriptionExtensionsSlice = createSlice({
  name: "subscriptionExtension",
  initialState,
  reducers: {
    setAvailableSubscriptionExtensions(state, action: PayloadAction<AvailableSubscriptionExtension[]>): void {
      state.availableSubscriptionExtensions = action.payload;
    }
  }
});

const availableSubscriptionExtensionReducer = availableSubscriptionExtensionsSlice.reducer;

export const { setAvailableSubscriptionExtensions } = availableSubscriptionExtensionsSlice.actions;
export default availableSubscriptionExtensionReducer;
