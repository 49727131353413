import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionExtensionState } from '../../types/redux';

const initialState: SubscriptionExtensionState = {
  subscriptionId: undefined,
  extensionTypeId: undefined,
  price: undefined,
};

const subscriptionExtensionSlice = createSlice({
  name: 'subscriptionExtension',
  initialState,
  reducers: {
    setSubscriptionExtension(state, action: PayloadAction<SubscriptionExtensionState>): void {
      state.subscriptionId = action.payload.subscriptionId;
      state.extensionTypeId = action.payload.extensionTypeId;
      state.price = action.payload.price;
    },
  },
});

const subscriptionExtensionReducer = subscriptionExtensionSlice.reducer;

export const { setSubscriptionExtension } = subscriptionExtensionSlice.actions;
export default subscriptionExtensionReducer;
