import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import RoundedCard from '../components/cards/RoundedCard';

export default function NotFoundPage(): JSX.Element {
  return (
    <DefaultLayout>
      <RoundedCard className="w-fit self-center">
        <h1 className="text-2xl font-bold">404 - Not Found</h1>
        <p className="text-lg text-gray-600">The page you are looking for does not exist.</p>
      </RoundedCard>
    </DefaultLayout>
  );
}
