import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { CgSpinnerAlt } from 'react-icons/cg';
import LabeledTextInput from '../../inputs/LabeledTextInput';
import Button from '../../buttons/Button';

type ResetPasswordFormProps = {
  onSubmit: (email: string) => void;
  loading?: boolean;
} & Omit<ComponentProps<'form'>, 'onSubmit'>;

export default function ForgottenPasswordForm({ onSubmit, loading, className, ...props }: ResetPasswordFormProps): JSX.Element {
  const { t } = useTranslation('reset_password_forms');
  const [email, setEmail] = React.useState<string>('');

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>): void => {
    ev.preventDefault();
    onSubmit(email);
  };

  return (
    <form onSubmit={handleSubmit} className={twMerge('flex flex-col gap-4', className)} {...props}>
      <LabeledTextInput
        type="email" name="recoveryEmail" id="recovery-email" label={`${t('recovery_email_label').toLowerCase()}: `}
        value={email} placeholder={t('recovery_email_placeholder')} onChange={(ev) => setEmail(ev.target.value)}
      />
      <Button type="submit" variant="pink-primary" className="flex items-center" disabled={loading}>
        {loading && <CgSpinnerAlt className="animate-spin mr-1" size={18} />} {t('send_email_button')}
      </Button>
    </form>
  );
}
