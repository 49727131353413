type CurrencyFormatOptions = {
  currency?: string;
  locale?: string;
};

/**
 * Format number to currency string
 *
 * @param amount the amount that need to be displayed
 * @param currency the currency the number need to be formatted in
 * @param locale the locale the number need to be formatted in
 * @returns
 */
export const formatCurrency = (
  amount: number,
  { currency = 'EUR', locale = 'nl-NL' }: CurrencyFormatOptions = {}
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount);
};