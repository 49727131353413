import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentSelectionState } from '../../types/redux';

const initialState: PaymentSelectionState = {
  paymentId: undefined,
};

const selectedPaymentSlice = createSlice({
  name: 'selectedPayment',
  initialState,
  reducers: {
    setSelectedPayment(state, action: PayloadAction<number | undefined>): void {
      state.paymentId = action.payload;
    },
  },
});

const selectedPaymentReducer = selectedPaymentSlice.reducer;

export const { setSelectedPayment } = selectedPaymentSlice.actions;
export default selectedPaymentReducer;
