import { IconType } from 'react-icons';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type IconButtonVariant = 'black' | 'pink';

type IconButtonProps = {
  icon: IconType;
  size?: 'sm' | 'md' | 'lg';
  variant?: IconButtonVariant;
  label?: string;
  className?: string;
  showLabelOnHoverOnly?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const buttonSizes = {
  sm: 'p-2 text-sm',
  md: 'p-3',
  lg: 'p-4 text-lg',
};

const variants: Record<IconButtonVariant, string> = {
  black: 'bg-black text-white',
  pink: 'bg-sb-pink text-white',
}

export function IconButton({ icon: Icon, label, size = 'md', variant = 'black', showLabelOnHoverOnly = false, className, ...props }: IconButtonProps): JSX.Element {
  return (
    <button type="button"
            className={twMerge(
              'rounded-sb-sm group',
              label ? 'flex items-center' : '',
              variants[variant],
              buttonSizes[size],
              className,
            )} {...props}>
      <Icon />{label && <span className={showLabelOnHoverOnly ? 'transition-all text-nowrap opacity-0 overflow-x-hidden duration-300 ease-out w-0 group-hover:w-24 group-hover:opacity-100' : 'ml-1'}>{label}</span>}
    </button>
  );
}
