import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RoundedCard from "../RoundedCard";
import { SubscriptionType } from '../../../types/subscriptions';
import { setSubscriptionExtension } from '../../../redux/slices/subscriptionExtensionSlice';
import useSubscriptionExtensionState from '../../../hooks/selectors/useSubscriptionExtensionState';

type SubscriptionCardProps = {
  subscriptionType: SubscriptionType;
}

export default function SubscriptionTypeCard({
                                                    subscriptionType: {
                                                      id,
                                                      name,
                                                      description,
                                                      price,
                                                      defaultRidesCount
                                                    },
                                                  }: SubscriptionCardProps): JSX.Element {
  const dispatch = useDispatch();
  const { subscriptionId, extensionTypeId } = useSubscriptionExtensionState();
  const { t } = useTranslation("subscription_type_card");
  const handleOnClickSubscriptionCard = (): void => {
    dispatch(setSubscriptionExtension({ subscriptionId, extensionTypeId: id, price }));
  }

  return (
    <RoundedCard
      onClick={handleOnClickSubscriptionCard}
      className={`flex justify-between items-center ${extensionTypeId === id ? `bg-sb-dark-blue` : `bg-sb-purple`} 
      text-white rounded-sb-sm bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500 
      hover:cursor-pointer`}>
      <div>
        <p className="font-bold">{t('name')} {name}</p>
        <p className="text-sm font-light">{t('description')} {description}</p>
        {
          defaultRidesCount === 0 ?
            <p className="font-bold">{t('rides')} {t('unlimited')}</p> :
            <p className="font-bold">{t('rides')} {defaultRidesCount}</p>
        }
        <p className="font-bold">{t('price')} {price}&euro;</p>
      </div>
    </RoundedCard>
  );
}
