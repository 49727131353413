import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ResetPasswordForm from '../../components/forms/auth/ResetPasswordForm';
import RoundedCard from '../../components/cards/RoundedCard';
import useQueryParams from '../../hooks/useQuery';
import { resetPassword } from '../../services/authService';

export default function ResetPasswordPage(): JSX.Element {
  const { t } = useTranslation('reset_password_forms');
  const { token } = useQueryParams<{ token?: string; }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      toast.error(t('invalid_token', { toastId: 'invalid_token' }));
      navigate('/forgotten-password');
    }
  }, [token]);

  const onSubmit = (newPassword: string): void => {
    if (token) {
      resetPassword(token, newPassword)
        .then(() => {
          toast.success(t('password_reset_success', { toastId: 'password_reset_success' }));
          navigate('/login');
        })
        .catch(() => {
          toast.error(t('password_reset_failed', { toastId: 'password_reset_failed' }));
          navigate('/forgotten-password');
        });
    } else {
      toast.error(t('invalid_token', { toastId: 'invalid_token' }));
      navigate('/forgotten-password');
    }
  };

  return (
    <RoundedCard variant="centered-form">
      <h2 className="text-2xl font-bold text-center">{t('password_reset_title')}</h2>
      <ResetPasswordForm onSubmit={onSubmit} />
    </RoundedCard>
  );
}
