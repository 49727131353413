import { store } from "../redux/store";
import { CreateTicketPaymentResponceDto, PaymentMethod, PaymentMethodId, PaymentType, RebookPaymentDto, SubscriptionExtensionPaymentDto, Payment } from "../types/payment";
import httpService from "./httpService";

export function getPaymentMethods(): Promise<PaymentMethod[]> {
    return httpService
      .get<PaymentMethod[]>('/api/paymentmethods')
      .then(({ data }): PaymentMethod[] => data);
  }

  export function createPayment(method: PaymentMethodId, typeOfPayment: PaymentType): Promise<any> {
    switch (typeOfPayment) {
        case PaymentType.PAYMENT_REBOOKING:
            return createPaymentRebooking(method);
        case PaymentType.SUBSCRIPTION_EXTENSION:
            return createSubscriptionExtensionPayment(method);
        default:
            return Promise.reject(new Error('Invalid payment type'));
    }
  }

  function createPaymentRebooking(method: PaymentMethodId): Promise<any> {
    return httpService.post<CreateTicketPaymentResponceDto>('/api/me/reservations/rebook', getRebookingPaymentDto(method))
        .then(({ data }) => data.url)
  }

  function getRebookingPaymentDto(method: PaymentMethodId): RebookPaymentDto {
    return {
        method: method.method,
        issuer: method.issuer,
        // TODO: Implement this function below
        paymentId: 1,
        bookingFee: 1,
        purchasesByTicketType: [],
    };
  }


  function createSubscriptionExtensionPayment(method: PaymentMethodId): Promise<any> {
    const {dto, subscriptionId} = getSubscriptionExtensionPaymentDto(method);
    return httpService.post<CreateTicketPaymentResponceDto>(`/api/user/@me/subscriptions/${subscriptionId}/extend/`, dto)
        .then(({ data }) => data.url)
  }

  function getSubscriptionExtensionPaymentDto(method: PaymentMethodId): {
    dto: SubscriptionExtensionPaymentDto,
    subscriptionId: number,
  } {
    const {subscriptionId, extensionTypeId, price} = store.getState().subscriptionExtension;
    if (!subscriptionId || !extensionTypeId || !price) {
      throw new Error('redux_state_error');
    }
    return {
        dto: {
            method: method.method,
            issuer: method.issuer,
            newSubscriptionTypeId: extensionTypeId,
            price,
        },
        subscriptionId,
    }
  }
export async function getPayments(): Promise<Payment[]> {
  return httpService.get<Payment[]>('/api/me/payments')
    .then((res) => res.data);
}
