import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentState } from '../../types/redux';
import { Payment } from '../../types/payment';

const initialState: PaymentState = {
  payments: [],
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPayments(state, action: PayloadAction<Payment[]>): void {
      state.payments = action.payload;
    },
  },
});

const paymentReducer = paymentsSlice.reducer;

export const { setPayments } = paymentsSlice.actions;
export default paymentReducer;
