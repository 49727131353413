import React, { HTMLAttributes } from 'react';
import { useDispatch } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import useLanguage from '../../hooks/selectors/useLanguage';
import { Language } from '../../types/misc';
import { setLanguage } from '../../redux/slices/applicationSlice';

export default function LanguageMenu({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div className={twMerge('flex items-center gap-2 rounded-full bg-black px-4 py-2', className)} {...props}>
      <LanguageOption language={Language.EN} label="EN" />
      <LanguageOption language={Language.NL} label="NL" />
    </div>
  );
}

function LanguageOption({ language, label }: { language: Language, label: string }): JSX.Element {
  const currentLanguage = useLanguage();
  const dispatch = useDispatch();

  return (
    <button className={`font-light ${currentLanguage === language ? 'text-white' : 'text-gray-600'}`} type="button"
            onClick={() => dispatch(setLanguage(language))}>{label}</button>

  );
}
