import httpService from './httpService';
import {
  AvailableSlot, AvailableSubscriptionExtension,
  Subscription,
  SubscriptionReservationAvailableDaysDto,
  SubscriptionReservationAvailableSlotsDto,
} from '../types/subscriptions';
import { Reservation } from '../types/reservations';

export async function getSubscriptions(): Promise<Subscription[]> {
  return httpService.get<Subscription[]>('/api/me/subscriptions')
    .then((res) => res.data);
}

export async function getReservableDays(subscriptionId: number | undefined, cardholders: number, from: Date, to: Date): Promise<SubscriptionReservationAvailableDaysDto> {
  if (subscriptionId === undefined) {
    return { availableDays: [] };
  }

  return httpService
    .get<SubscriptionReservationAvailableDaysDto>(`/api/user/@me/subscriptions/${subscriptionId}/available-days/?cardholders=${cardholders}&from=${from.toISOString()}&to=${to.toISOString()}`)
    .then((res) => {
      return res.data
    });
}

export async function getReservableSlots(subscriptionId: number | undefined, dayId: number | undefined, cardholders: number[]): Promise<AvailableSlot[]> {

  if (!subscriptionId || !dayId) {
    return [];
  }

  const cardholderParams = cardholders.map(id => `cardholders=${id}`).join('&');

  return httpService
    .get<SubscriptionReservationAvailableSlotsDto>(`/api/user/@me/subscriptions/${subscriptionId}/available-days/${dayId}/available-slots?${cardholderParams}`)
    .then((res) => {
      return res.data.availableSlots;
    });
}

export async function createReservation(subscriptionId: number | undefined, slotId: number, cardholderIds: number[]): Promise<Reservation[]> {

  if (!subscriptionId) {
    return [];
  }

  const reservationDto = {
    cardholderIds,
    slotId
  }

  return httpService
    .post<Reservation[]>(`/api/user/@me/subscriptions/${subscriptionId}/reservations`, reservationDto)
    .then(({ data }) => data);
}

export async function getAvailableSubscriptionExtension(): Promise<AvailableSubscriptionExtension[]> {
  return httpService
    .get<AvailableSubscriptionExtension[]>(`/api/user/@me/subscriptions/available-extensions`)
    .then((res) => {
      console.log(res.data);
      return res.data;
    });
}
export async function downloadQrCodesCardholders(cardholdersQrCodes: string[]): Promise<Buffer> {
  return httpService
    .post<Buffer>(`/api/me/cardholders/download`, {uuids: cardholdersQrCodes}, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
    .then(({ data }) => data);
}
