import React from 'react';
import { Purchase } from '../../types/payment';
import PurchaseSubscriptionTable from '../tables/PurchaseSubscriptionTable';

type PurchaseCardProps = {
  purchases: Purchase[];
  bookingFee: number;
  timeZone: string;
}

export default function SubscriptionPurchaseCard({ purchases, bookingFee, timeZone }: PurchaseCardProps): JSX.Element {
  return (
    <div className="flex flex-col gap-1">
      <div className="relative overflow-x-auto no-scrollbar">
        <PurchaseSubscriptionTable purchases={purchases} bookingFee={bookingFee} timeZone={timeZone}/>
      </div>
    </div>
  )
}