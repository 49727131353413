import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import { Auth } from '../../types/accounts';

const selectIsAuthenticated = (state: RootState): boolean => state.application.isAuthenticated;
const selectEmail = (state: RootState): string | undefined => state.application.email;

const selectAuth =  createSelector(
  [selectIsAuthenticated, selectEmail],
  (isAuthenticated, email) => (isAuthenticated) ? { email: email! } : undefined);

export default function useAuth(): Auth | undefined {
  return useSelector(selectAuth);
}
