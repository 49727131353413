import { format, toZonedTime } from 'date-fns-tz';
import { nl, enUS } from 'date-fns/locale';
import useLanguage from './selectors/useLanguage';
import useSubscriptions from './selectors/useSubscriptions';
import useSubscriptionExtensionState from './selectors/useSubscriptionExtensionState';

export default function useDateFormatSubscriptionExtension(): typeof format {
  const lang = useLanguage();
  const { subscriptionId } = useSubscriptionExtensionState();
  const subscriptions = useSubscriptions();
  const timeZone = subscriptions.find(s => s.id === subscriptionId)?.subscriptionType.location.timezone ?? 'UTC';

  return (date, formatString, options = {}): string => {
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, formatString, {
      locale: lang === 'nl' ? nl : enUS,
      timeZone,
      ...options,
    }).toLowerCase();
  };
}