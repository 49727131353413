import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import useSelectedPayment from '../../hooks/selectors/useSelectedPayment';
import { setSelectedPayment } from '../../redux/slices/selectedPaymentSlice';
import { getPayments } from '../../services/paymentService';
import { setPayments } from '../../redux/slices/paymentSlice';
import { PaymentWidget } from '../../components/widgets/paymentpage/PaymentWidget';
import { PurchaseWidget } from '../../components/widgets/paymentpage/PurchaseWidget';

export function PaymentsPage(): JSX.Element {
  const dispatch = useDispatch();
  const paymentId = useSelectedPayment();

  useEffect(() => {
    dispatch(setSelectedPayment(undefined));
    getPayments()
      .then(res => dispatch(setPayments(res)))
  }, []);

  return (<main className="grid gap-4 grid-cols-2 max-lg:grid-cols-2 max-sm:grid-cols-1">
    <PaymentWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    {
      paymentId &&
      <PurchaseWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    }
  </main>)
}
