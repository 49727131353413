import { TbDownload } from 'react-icons/tb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RoundedCard from './RoundedCard';
import { getDateString, getTimespanString } from '../../utils/dateUtil';
import { IconButton } from '../buttons/IconButton';
import { Reservation } from '../../types/reservations';
import { downloadPdfReservationById } from '../../services/reservationService';

type TicketCardProps = {
  reservation: Reservation
}

export default function TicketCard({
                                     reservation: {
                                       id: reservationId,
                                       ticketTypeOnSlot: { ticketType, slot },
                                     },
                                   }: TicketCardProps): JSX.Element {
  const { t } = useTranslation('tickets_widget');

  const downloadTicket = (): void => {
    downloadPdfReservationById([reservationId])
      .then((pdfBuffer) => {
        const url = URL.createObjectURL(new Blob([pdfBuffer]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `ticket-${reservationId}.pdf`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch(() => toast.error(t('download_error')));
  };

  return (
    <RoundedCard
      className="flex justify-between items-center bg-gradient-to-r from-sb-purple to-sb-pink text-white rounded-sb-sm bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500">
      <div>
        <p
          className="font-bold">{ticketType.name} {!ticketType.alwaysAvailable && `(${getTimespanString(slot)})`}</p>
        <p
          className="text-sm font-light">{ticketType.description}, {ticketType.alwaysAvailable ? `${t('valid_until')} ${getDateString(slot.endTime)}` : `${t('valid_on')} ${getDateString(slot.startTime)}`}</p>
      </div>
      <div className="flex gap-1">
        {
          ticketType.requireQRCode &&
          <IconButton
            icon={TbDownload} label={t('download_ticket')} showLabelOnHoverOnly size="sm" onClick={downloadTicket}
          />
        }
      </div>
    </RoundedCard>
  );
}
