import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TextInputProps = {
  text?: string;
  setText?: (text: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export default function TextInput({
  text,
  setText,
  className,
  value,
  onChange,
  ...props
}: TextInputProps): JSX.Element {
  return (
    <input
      className={twMerge(
        'w-full h-[60px] px-6 rounded-sb-md border-none bg-sb-light-blue placeholder:text-opacity-35 placeholder:text-black leading-[21px] focus:ring-sb-purple focus:ring-2 focus:ring-offset-2', className)}
      value={value ?? text ?? ''}
      onChange={onChange ?? ((e): void => setText?.(e.target.value))}
      {...props}
    />
  );
}
