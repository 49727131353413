import React, { forwardRef, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { addDays, addMonths, isSameDay, isSameMonth, startOfWeek } from 'date-fns';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import RoundedCard from '../../cards/RoundedCard';
import { getReservableDays } from '../../../services/subscriptionService';

import {
  absoluteMonthDifference,
  dateMax,
  getMonthWeeks,
  isBeforeDay, isWithinDaySpan,
  makeTimeIrrelevantDate,
} from '../../../utils/dateUtil';
import useSubscriptionBookingState from '../../../hooks/selectors/useSubscriptionBookingState';
import IconButtonDate from '../../buttons/IconButtonDate';
import { setAvailableDaysSubscriptionBooking } from '../../../redux/slices/availableDaysSubscriptionBookingSlice';
import { setSubscriptionBooking } from '../../../redux/slices/subscriptionBookingSlice';
import useAvailableDaysSubscriptionBooking from '../../../hooks/selectors/useAvailableDaysSubscriptionBooking';
import useDateFormatSubscriptionBooking from '../../../hooks/useDateFormatSubscriptionBooking';

export type SubscriptionBookingCalendarWidgetProps = {
}

export function SubscriptionBookingCalendarWidget({ className, ...props }: HTMLAttributes<HTMLDivElement> & SubscriptionBookingCalendarWidgetProps): JSX.Element {
  const { t } = useTranslation('subscriptions_calendar_widget');
  const availableDays = useAvailableDaysSubscriptionBooking();
  const bookingState = useSubscriptionBookingState();
  const ticketCalendarRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getReservableDays(bookingState.subscriptionId, bookingState.cardholderIds.length, new Date(), new Date(new Date(Date.now()).getTime() + 1000 * 60 * 60 * 24 * 60))
      .then(dto => {
        dispatch(setAvailableDaysSubscriptionBooking(dto));
      });
  }, [bookingState.cardholderIds]);

  return (
    <RoundedCard className={twMerge(`flex flex-col visible:${!!availableDays && availableDays.availableDays.length > 0}`, className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full">
        <div>
          <h2 className="text-xl font-bold py-2">{t('available_days')}</h2>
          <TicketCalendar
            ref={ticketCalendarRef}
            className="top-0 w-full transition-all duration-300 ease-in-out translate-y-0 opacity-100"
          />
          <div className="flex flex-col gap-1" />
        </div>
      </div>
    </RoundedCard>
  );
}

interface TicketCalendarProps {
  className?: string;
  onChange?: (date: Date) => void;
}

const TicketCalendar = forwardRef<HTMLDivElement, TicketCalendarProps>(
  ({ className, onChange }, ref) => {
    const format = useDateFormatSubscriptionBooking();
    const { subscriptionId, day, cardholderIds } = useSubscriptionBookingState();
    const dispatch = useDispatch();
    const [monthIndex, setMonthIndex] = useState<number>(
      absoluteMonthDifference(day, new Date()),
    );
    const { availableDays } = useAvailableDaysSubscriptionBooking();

    const month = addMonths(new Date(), monthIndex);
    const monthWeeks = getMonthWeeks(monthIndex).flat();

    useEffect(() => {
      getReservableDays(subscriptionId, cardholderIds.length, dateMax(monthWeeks[0], makeTimeIrrelevantDate(new Date())), monthWeeks[monthWeeks.length - 1])
        .then(data => dispatch(setAvailableDaysSubscriptionBooking(data)))
    }, [monthIndex, cardholderIds]);

    return (
      <div ref={ref} className={twMerge('flex flex-col gap-2', className)}>
        <div className="flex justify-between w-full max-w-[320px] gap-2 mx-auto items-center">
          <IconButtonDate
            disabled={monthIndex === 0}
            onClick={() => setMonthIndex(monthIndex - 1)}
            className="bg-black text-white h-[60px] w-[60px]"
            iconClassName="h-[20px] w-[20px]"
            icon={FaChevronLeft}
          />
          <p className="text-[21px] leading-[17px] whitespace-nowrap">
            {format(month, 'LLLL yyyy')}
          </p>
          <IconButtonDate
            onClick={() => setMonthIndex(monthIndex + 1)}
            className="bg-black text-white h-[60px] w-[60px]"
            iconClassName="h-[20px] w-[20px]"
            icon={FaChevronRight}
          />
        </div>
        <div className="grid grid-cols-7 gap-x-2 gap-y-2.5 w-fit mx-auto items-center">
          {Array.from({ length: 7 }, (_, i) =>
            format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), i), 'EEEEEE'),
          ).map((d) => (
            <label key={d} className="flex justify-center text-[17px] leading-[22px]">
              {d}
            </label>
          ))}
          {monthWeeks.map((d, i) => {
            // console.log("These are the reals", availableDays)
            const disabled = isBeforeDay(d, new Date()) || availableDays.every(availableDay => !isWithinDaySpan(d, availableDay));
            const greyed = !isSameMonth(d, month) || disabled;
            return (
              <div
                onClick={() => {
                  if (disabled) return;
                  if (!isSameMonth(d, month)) setMonthIndex((prev) => prev + (d < month ? -1 : 1));
                  onChange?.(d);
                  dispatch(setSubscriptionBooking({ subscriptionId, cardholderIds, day: d }));
                }}
                key={i}
                className={twMerge(
                  'flex flex-col w-fit h-fit min-w-[40px] gap-1 items-center p-2.5 px-1 mx-auto rounded-sb-sm bg-sb-light-pink',
                  classNames({
                    'bg-sb-purple text-white': isSameDay(day, d) && isSameMonth(d, month),
                    'bg-opacity-10 bg-[#979797]': greyed,
                    'cursor-pointer': !disabled,
                  }),
                )}>
                <p className={classNames('text-[17px] leading-[17px] font-ginto-bold', { 'opacity-20': greyed })}>
                  {format(d, 'dd')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
