import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookDayState } from '../../types/redux';

const initialState: BookDayState = {
  bookDay: new Date(),
};

const bookDaySlice = createSlice({
  name: 'bookDay',
  initialState,
  reducers: {
    setBookDay(state, action: PayloadAction<Date>): void {
      state.bookDay = action.payload;
    },
  },
});

const bookDayReducer = bookDaySlice.reducer;

export const { setBookDay } = bookDaySlice.actions;
export default bookDayReducer;
