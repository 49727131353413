import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionsState } from '../../types/redux';
import { Subscription } from '../../types/subscriptions';

const initialState: SubscriptionsState = {
  subscriptions: [],
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions(state, action: PayloadAction<Subscription[]>): void {
      state.subscriptions = action.payload;
    },
  },
});

const subscriptionsReducer = subscriptionsSlice.reducer;

export const { setSubscriptions } = subscriptionsSlice.actions;
export default subscriptionsReducer;
