import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import RoundedCard from '../../cards/RoundedCard';
import useAvailableSubscriptionsExtension from '../../../hooks/selectors/useAvailableSubscriptionsExtension';
import SubscriptionExtensionCard from '../../cards/extension/SubscriptionExtensionCard';

export function SubscriptionExtensionWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const availableSubscriptions = useAvailableSubscriptionsExtension();
  const { t } = useTranslation('subscriptions_extension_widget');

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        <div>
          <h2 className="text-xl font-bold py-2">{t('extendable_subscriptions')}</h2>
          <div className="flex flex-col gap-1">
            {availableSubscriptions.map(({ subscription }) => <SubscriptionExtensionCard id={subscription.id} />)}
          </div>
        </div>
      </div>
    </RoundedCard>
  );
}
