import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function useCrashHandler(): (err: Error) => void {
  const te = useTranslation('error').t;

  return (err: Error) => {
    switch (true) {
        case err.message.includes('Network Error'):
            toast.error(te('network_error'));
            break;
        default:
            toast.error(te('went_wrong'));
            console.error(err);
    }
  };
}
