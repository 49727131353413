import { AnyAction, CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';
import { RootState } from '../types/redux';
import applicationReducer from './slices/applicationSlice';
import reservationsReducer from './slices/reservationsSlice';
import subscriptionsReducer from './slices/subscriptionsSlice';
import bookDayReducer from './slices/bookDaySlice';
import subscriptionBookingReducer from './slices/subscriptionBookingSlice';
import availableDaysSubscriptionBookingReducer from './slices/availableDaysSubscriptionBookingSlice';
import availableSubscriptionExtensionReducer from './slices/availableSubscriptionExtensionsSlice';
import subscriptionExtensionReducer from './slices/subscriptionExtensionSlice';
import paymentReducer from './slices/paymentSlice';
import selectedPaymentReducer from './slices/selectedPaymentSlice';
import selectedSubscriptionReducer from './slices/selectedSubscriptionSlice';

const rootReducer = (state: RootState, action: AnyAction): CombinedState<RootState> => {
  if (action.type === 'application/setAuthenticated' && action.payload === false) {
    localStorage.removeItem('persist:root');

    state = {
      application: { language: state.application.language, isAuthenticated: false },
      reservations: { reservations: [] },
      subscriptions: { subscriptions: [] },
      bookDay: { bookDay: new Date() },
      subscriptionBooking: { subscriptionId: state.subscriptionBooking.subscriptionId, day: state.subscriptionBooking.day, cardholderIds: state.subscriptionBooking.cardholderIds },
      availableDaysSubscriptionBooking: { availableDays: [] },
      availableSubscriptionExtensions: { availableSubscriptionExtensions: [] },
      subscriptionExtension: { subscriptionId: state.subscriptionExtension.subscriptionId, extensionTypeId: state.subscriptionExtension.subscriptionId, price: state.subscriptionExtension.price },
      payments: { payments: [] },
      selectedPayment: { paymentId: undefined },
      selectedSubscription: { subscriptionId: undefined }
    };
  }

  return combineReducers({
    application: applicationReducer,
    reservations: reservationsReducer,
    subscriptions: subscriptionsReducer,
    bookDay: bookDayReducer,
    subscriptionBooking: subscriptionBookingReducer,
    availableDaysSubscriptionBooking: availableDaysSubscriptionBookingReducer,
    availableSubscriptionExtensions: availableSubscriptionExtensionReducer,
    subscriptionExtension: subscriptionExtensionReducer,
    payments: paymentReducer,
    selectedPayment: selectedPaymentReducer,
    selectedSubscription: selectedSubscriptionReducer
  })(state, action);
};

export default rootReducer as Reducer<RootState, AnyAction>;
