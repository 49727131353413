import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableDaysSubscriptionBookingState } from '../../types/redux';

const initialState: AvailableDaysSubscriptionBookingState = {
  availableDays: []
};

const availableDaysSubscriptionBookingSlice = createSlice({
  name: 'availableDaysSubscriptionBookingState',
  initialState,
  reducers: {
    setAvailableDaysSubscriptionBooking(state, action: PayloadAction<AvailableDaysSubscriptionBookingState>): void {
      state.availableDays = action.payload.availableDays;
    },
  },
});

const availableDaysSubscriptionBookingReducer = availableDaysSubscriptionBookingSlice.reducer;

export const { setAvailableDaysSubscriptionBooking } = availableDaysSubscriptionBookingSlice.actions;
export default availableDaysSubscriptionBookingReducer;
