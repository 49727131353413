import React, { ComponentProps } from 'react';
import TextInput from './TextInput';

type LabeledTextInputProps = {
  id: string;
  label?: string;
} & Omit<ComponentProps<typeof TextInput>, 'id'>;

export default function LabeledTextInput({
  label = '',
  placeholder,
  id,
  ...props
}: LabeledTextInputProps): JSX.Element {
  return (
    <div className="flex flex-col gap-1.5">
      <label htmlFor={id} className="ml-2">
        {label}
      </label>
      <TextInput placeholder={placeholder ?? label} id={id} {...props} />
    </div>
  );
}
