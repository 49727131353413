import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { getAvailableSubscriptionExtension } from '../../services/subscriptionService';
import { setAvailableSubscriptionExtensions } from '../../redux/slices/availableSubscriptionExtensionsSlice';
import { SubscriptionExtensionWidget } from '../../components/widgets/extensionpage/SubscriptionExtensionWidget';
import useSubscriptionExtensionState from '../../hooks/selectors/useSubscriptionExtensionState';
import {
  SubscriptionTypeExtensionWidget
} from '../../components/widgets/extensionpage/SubscriptionTypeExtensionWidget';
import { setSubscriptionExtension } from '../../redux/slices/subscriptionExtensionSlice';

export function SubscriptionExtensionPage(): JSX.Element {
  const dispatch = useDispatch();
  const { subscriptionId } = useSubscriptionExtensionState();

  useEffect(() => {
    dispatch(setSubscriptionExtension({ subscriptionId: undefined, extensionTypeId: undefined, price: undefined }));
    getAvailableSubscriptionExtension()
      .then(res => dispatch(setAvailableSubscriptionExtensions(res)))
  }, []);

  return (<main className="grid gap-4 grid-cols-2 max-lg:grid-cols-2 max-sm:grid-cols-1">
    <SubscriptionExtensionWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    {
      subscriptionId &&
      <SubscriptionTypeExtensionWidget className="col-span-1 max-sm:col-span-1 min-h-52" />
    }
  </main>)
}
