import React from 'react';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';

type CircleIconButtonProps = {
  icon: IconType;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

const buttonSizes = {
  sm: 'p-2 text-sm',
  md: 'p-3',
  lg: 'p-4 text-lg',
};

export function CircleIconButton({ icon: Icon, size = 'md', className, ...props }: CircleIconButtonProps): JSX.Element {
  return (
    <button type="button"
            className={twMerge(
              'aspect-square bg-black text-white rounded-full',
              buttonSizes[size],
              className,
            )} {...props}>
      <Icon />
    </button>
  );
}
