import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../components/buttons/Button';
import CheckboxInput from '../components/inputs/CheckboxInput';
import MessageBubble from '../components/misc/MessageBubble';
import { PaymentMethod, PaymentMethodId, PaymentType } from '../types/payment';
import ContentPanel from '../components/layouts/ContentPanel';
import useQueryParams from '../hooks/useQuery';
import * as paymentService from '../services/paymentService';
import DropdownInputPayment from '../components/inputs/DropdownInputPayment';
import useCrashHandler from '../hooks/useCrashHandler';
import useSubscriptionExtensionState from '../hooks/selectors/useSubscriptionExtensionState';

export default function PaymentPage(): JSX.Element {
  const { paymentType } = useQueryParams<{paymentType?: PaymentType}>();
  const price  = 
  {
    [PaymentType.SUBSCRIPTION_EXTENSION]: useSubscriptionExtensionState().price,
    [PaymentType.PAYMENT_REBOOKING]: 20,
  } [paymentType as PaymentType] ?? undefined;
  const navigate = useNavigate();
  const crashHandler = useCrashHandler();
  const { t } = useTranslation('payment');
  const te = useTranslation('error').t;

  const handleError = (error: any): void => {
    if ( !error.response.status ) {
      crashHandler(error);
      return;
    }
    toast.error(te('went_wrong'));
    switch (paymentType) {
        case PaymentType.PAYMENT_REBOOKING:
            navigate('/reservations/rebook');
            break;
        case PaymentType.SUBSCRIPTION_EXTENSION:
            navigate('/subscriptions-extend');
            break;
        default:
            navigate('');
            break;
    }
  };

  const [methods, setMethods] = useState<PaymentMethod[]>([]);
  const selectedMethodState = useState<PaymentMethodId | undefined>();
  const consentState = useState<boolean>(false);

  useEffect((): void => {
    if (!paymentType || !price) {
        navigate('');
    }
    paymentService.getPaymentMethods().then(setMethods)
    .catch(crashHandler);
  }, []);



  const { method, issuer } = selectedMethodState[0] || {};
  const canSubmit =
    method && (!methods.find((m) => m.id === method)?.issuers || issuer) && consentState[0];

  

  const handleSubmit = (): void => {
    if (!canSubmit) return;

    paymentService
      .createPayment({ method, issuer }, paymentType ?? PaymentType.PAYMENT_REBOOKING)
      .then((url) => {
        window.location.href = url;
      })
      .catch(handleError);
  };

  return (
    <ContentPanel className='mx-auto'>
      <PaymentOptions selectedMethodState={selectedMethodState} methods={methods} />
      <ConsentCheckboxes consentState={consentState} />
      <MessageBubble>{t('warningNoInfo')}</MessageBubble>
      <Button variant="pink-primary" disabled={!canSubmit} className="w-full" onClick={handleSubmit}>
        {t('button')} (€{price?.toFixed(2) ?? `-,-`})
      </Button>
    </ContentPanel>
  );
}

interface PaymentOptionsProps {
  methods: PaymentMethod[];
  selectedMethodState: [
    PaymentMethodId | undefined,
    React.Dispatch<React.SetStateAction<PaymentMethodId | undefined>>,
  ];
}

function PaymentOptions({ methods, selectedMethodState }: PaymentOptionsProps): JSX.Element {
  const [selectedMethod, setSelectedMethod] = selectedMethodState;
  const { method, issuer } = selectedMethod || {};

  const { t } = useTranslation('payment');

  const handleMethodSelect = (id: string): void => {
    if (method === id) setSelectedMethod(undefined);
    else setSelectedMethod({ method: id });
  };

  const handleIssuerSelect = (id: string | undefined): void => {
    if (!method || issuer === id) return;
    setSelectedMethod({ method, issuer: id });
  };

  const issuers = methods.find((m) => m.id === method)?.issuers;

  return (
    <>
      <h1 className="font-ginto-bold text-xl text-[24px] leading-[24px]">{t('method')}</h1>
      <div className="flex flex-col gap-2">
        {methods.map((m) => (
          <div
            key={m.id}
            onClick={() => handleMethodSelect(m.id)}
            className={`w-full cursor-pointer rounded-sb-md flex justify-between items-center px-6 py-4 h-[80px] ${
              selectedMethodState[0]?.method === m.id
                ? 'bg-sb-light-purple'
                : 'border-black border border-opacity-30'
            }`}>
            <img src={m.image.svg} alt={m.description} className="h-full" />
            <p>{m.description}</p>
          </div>
        ))}
      </div>
      {issuers && (
        <>
          <h1 className="font-ginto-bold text-xl text-[24px] leading-[24px]">{t('issuer')}</h1>
          <DropdownInputPayment
            options={issuers.map(({ id, name, image: { svg } }) => ({
              value: id,
              label: name,
              image: svg,
            }))}
            value={issuer}
            setValue={handleIssuerSelect}
            placeholder="Selecteer jouw bank"
            isSearchable={false}
            styles={{
              control: () => ({
                height: '80px',
                padding: '1rem 1.25rem',
                borderColor: '#0000004D',
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                padding: '0',
                flex: '1 1 0%',
                width: '100%',
                height: '100%',
              }),
            }}
            formatOptionLabel={OptionLabel}
          />
        </>
      )}
    </>
  );
}

function OptionLabel({ label, image }: { label: string; image: string }): JSX.Element {
  return (
    <div className="flex items-center gap-2 pl-0.5 flex-1 w-full h-full my-auto">
      <img src={image} alt={label} className="h-12" />
      <p>{label}</p>
    </div>
  );
}

interface ConsentCheckboxesProps {
  consentState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

function ConsentCheckboxes({ consentState }: ConsentCheckboxesProps): JSX.Element {
  const { t } = useTranslation('payment');

  const [consentGiven, setConsentGiven] = consentState;

  return (
    <div className='flex flex-col gap-4 mt-8'>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" checked={consentGiven} setChecked={setConsentGiven} />
        <p className="text-sm font-ginto-bold">{t('termsAndConditions')}</p>
      </div>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" />
        <p className="text-sm">{t('newsletter')}</p>
      </div>
      <div className="flex gap-4">
        <CheckboxInput className="mt-[3px]" />
        <p className="text-sm">{t('noInfo')}</p>
      </div>
    </div>
  );
}
