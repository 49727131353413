import React from 'react';
import { useDispatch } from 'react-redux';
import RoundedCard from './RoundedCard';
import { getDateString } from '../../utils/dateUtil';
import { Subscription } from '../../types/subscriptions';
import CardholderCard from './CardholderCard';
import { setSubscriptionBooking } from '../../redux/slices/subscriptionBookingSlice';
import useSubscriptionBookingState from '../../hooks/selectors/useSubscriptionBookingState';

type SubscriptionCardProps = {
  subscription: Subscription;
  extend: boolean;
  setSubscriptionId: () => void;
}

export default function SubscriptionCard({
                                     subscription: {
                                       id: subscriptionId,
                                       expiry,
                                       cardholders,
                                       subscriptionType,
                                     },
                                     extend = false,
                                     setSubscriptionId,
                                   }: SubscriptionCardProps): JSX.Element {
  const state = useSubscriptionBookingState();
  const [cardholderIds, setCardholderIds] = React.useState<Set<number>>(new Set());
  const dispatch = useDispatch();
  const handleCardholderClick = (cardholderId: number): void => {
    setCardholderIds(prev => {
      const newSet = new Set(prev);  // Create a new Set
      if (newSet.has(cardholderId)) {
        newSet.delete(cardholderId);
      } else {
        newSet.add(cardholderId);
      }
      console.log(state);
      dispatch(setSubscriptionBooking({
        subscriptionId,
        cardholderIds: Array.from(newSet),
        day: state.day
      }));
      return newSet;  // Return the new Set
    });
  };

  const handleOnClickSubscriptionCard = (): void => {
    if (!extend) {
      setCardholderIds(new Set());
    }
    setSubscriptionId();
  }

  return (
    <RoundedCard
      onClick={handleOnClickSubscriptionCard}
      className="flex justify-between items-center bg-gradient-to-r from-sb-purple to-sb-light-pink text-white rounded-sb-sm bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500 hover:cursor-pointer">
      <div>
        <p className="font-bold">{subscriptionType.name}</p>
        <p className="text-sm font-light">{subscriptionType.description}</p>
        <p className="text-sm font-light">Expires at: {getDateString(expiry)}</p>
      </div>
      {
        extend &&
        <div className="flex flex-col gap-1">
          { cardholders.map(cardholder => <CardholderCard cardholder={cardholder} selected={cardholderIds.has(cardholder.id)} onClick={() => handleCardholderClick(cardholder.id)} />) }
        </div>
      }
    </RoundedCard>
  );
}
