import React, { HTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import useSubscriptions from '../../../hooks/selectors/useSubscriptions';
import RoundedCard from '../../cards/RoundedCard';
import SubscriptionCard from '../../cards/SubscriptionCard';
import { Subscription } from '../../../types/subscriptions';
import { getSubscriptions } from '../../../services/subscriptionService';
import { setSubscriptions } from '../../../redux/slices/subscriptionsSlice';
import { setSubscriptionBooking } from '../../../redux/slices/subscriptionBookingSlice';
import useSubscriptionBookingState from '../../../hooks/selectors/useSubscriptionBookingState';

export function SubscriptionsWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const dispatch = useDispatch();
  const subscriptions = useSubscriptions().filter(subscription => !subscription.subscriptionType.alwaysAvailable);
  const { t } = useTranslation('subscriptions_widget');
  const { day } = useSubscriptionBookingState();
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | undefined>(undefined);

  useEffect(() => {
    getSubscriptions()
      .then((sbs) => dispatch(setSubscriptions(sbs)))
      .catch(() => toast.error(t('subscriptions_error')));
  }, []);
  
  const handleSubscriptionSelection = (subscription: Subscription): void => {
    if (selectedSubscriptionId === subscription.id) {
      return
    }

    setSelectedSubscriptionId(subscription.id);
    dispatch(setSubscriptionBooking({ subscriptionId: subscription.id, cardholderIds: [], day }));
  }

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
        <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
          <div>
            <h2 className="text-xl font-bold py-2">{t('bookable_subscriptions')}</h2>
            <div className="flex flex-col gap-1">
              {subscriptions.map((subscription) => <SubscriptionCard subscription={subscription} extend={selectedSubscriptionId === subscription.id} setSubscriptionId={() => handleSubscriptionSelection(subscription)} />)}
            </div>
          </div>
        </div>
    </RoundedCard>
  );
}
