import React from 'react';
import RoundedCard from './RoundedCard';
import { Cardholder } from '../../types/card-holders';

type CardholderCardProps = {
  cardholder: Cardholder;
  selected: boolean;
  onClick: () => void;
}

export default function CardholderCard({
                                         cardholder: {
                                           id: cardholderId,
                                           identity: {
                                             firstName,
                                             lastName
                                           },
                                           disabled
                                         },
                                         selected,
                                         onClick,
                                         }: CardholderCardProps): JSX.Element {

  if (disabled) {
    return (<></>)
  }
  return (
    <RoundedCard onClick={onClick} key={`cardholder-${cardholderId}`}
      className={`flex justify-between items-center bg-sb-pink text-white rounded-sb-sm bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500 ${selected ? 'bg-sb-dark-blue' : 'drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'} hover:cursor-pointer`}>
      <div>
        <p className="font-bold">{firstName} {lastName}</p>
      </div>
    </RoundedCard>
  );
}