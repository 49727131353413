import { Location } from './subscriptions';
import { RebookTicketPurchaseByTicketTypeIdDto } from "./purchase";

export interface PaymentImage {
  size1x: string;
  size2x: string;
  svg: string;
}

export interface PaymentAmount {
  value: string;
  currency: string;
}

export interface PaymentIssuer {
  resource: string;
  id: string;
  name: string;
  image: PaymentImage;
}

export interface PaymentMethod {
  resource: string;
  id: string;
  description: string;
  minimumAmount: PaymentAmount;
  maximumAmount: PaymentAmount;
  image: PaymentImage;
  issuers?: PaymentIssuer[];
}

export type CreateTicketPaymentResponceDto = {
  url: string;
}

export enum PaymentType {
  PAYMENT_REBOOKING = 'payment_rebook',
  SUBSCRIPTION_EXTENSION = 'subscription_extension',
}

export interface PaymentMethodId {
  method: string;
  issuer?: string;
}

export interface RebookPaymentDto extends PaymentMethodId {
  paymentId: number,
  bookingFee: number,
  purchasesByTicketType: RebookTicketPurchaseByTicketTypeIdDto[],
}

export interface SubscriptionExtensionPaymentDto extends PaymentMethodId {
  newSubscriptionTypeId: number,
  price: number,
}


export type Payment = {
  id: number;
  date: Date;
  userId: number | null;
  locationId: number;
  extendedPaymentData: ExtendedPaymentData;
  paymentReference: string;
  status: PaymentStatus;
  method: string;
  bookingFee: number;
  purchases: Purchase[];
  discounts: Discount[];
  location: Location;
}

export type Discount = {
  code: string
}

export type Purchase = {
  id: number,
  price: number,
  originalPrice: number,
  type: string,
  refundPaymentId: number | null,
  extendedPurchaseData: ExtendedPurchaseData,
}

export type Rotterdampas = {
  id: number,
  voucherId: string,
  cardNumber: string,
  cardStatus: string,
  voucherName: string,
  transactionId: string,
}

export type PaymentFilter = {
  status: PaymentStatus,
  type: PurchaseType,
}

export type ExtendedPaymentData = {
  user: ExtendedPaymentUserData,
  account: ExtendedPaymentAccountData,
  location: ExtendedPaymentLocationData,
  shop: ExtendedPaymentShopData
}

export type ExtendedPaymentShopData = {
  type: ShopType
  name?: string,
}

export type ExtendedPaymentLocationData = {
  name: string,
}

export type ExtendedPaymentUserData = {
  firstName: string,
  lastName: string,
}

export type ExtendedPaymentAccountData = {
  email: string,
}

export type ExtendedPurchaseData = {
  reservation?: ExtendedPurchaseReservation,
  subscription?: ExtendedPurchaseSubscriptionData,
  rotterdampasses: Rotterdampas[]
}

export type ExtendedPurchaseSubscriptionData = {
  id: number,
  subscriptionType: ExtendedPurchaseSubscriptionTypeData,
  cardholders: ExtendedPurchaseCardholderData[]
}

export type ExtendedPurchaseCardholderData = {
  qrCodeValue: string
}

export type ExtendedPurchaseSubscriptionTypeData = {
  name: string,
}

export type ExtendedPurchaseReservation = {
  id: number,
  status: ReservationStatus,
  ticketTypeOnSlot: ExtendedPurchaseTicketTypeOnSlotData,
  qrCode: ExtendedPurchaseDataQrCode
}

export enum ReservationStatus {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  USED = "USED",
  CANCELLED = "CANCELLED"
}

export enum RotterdampasCardStatus {
  CLAIMED = "CLAIMED",
  REDEEM_FAILED = 'REDEEM_FAILED',
  CANCELED = 'CANCELED',
  CANCEL_PENDING = 'CANCEL_PENDING',
  CANCEL_FAILED = 'CANCEL_FAILED'
}

export enum ShopType {
  TICKET = "TICKET",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export type ExtendedPurchaseDataQrCode = {
  code: string,
}

export type ExtendedPurchaseTicketTypeOnSlotData = {
  ticketType: ExtendedPurchaseTicketTypeData,
}

export type ExtendedPurchaseTicketTypeData = {
  name: string,
}

export type ConfirmationEmail = {
  email?: string,
}

export type RefundPaymentData = {
  purchases: number[],
  cancellationFee: number
}

export enum PaymentStatus {
  ALL = 'all',
  PAID = 'paid',
  OPEN = 'open',
  FAILED = 'failed',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
  PROCESSING = 'processing',
  REFUNDED = 'refunded',
  NOTISSUED = 'notissued'
}
export enum PurchaseType {
  ALL = 'ALL',
  TICKET = 'TICKET',
  SUBSCRIPTION = 'SUBSCRIPTION',
  REFUND = 'REFUND'
}
