import QRCode from 'react-qr-code';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateString } from '../../utils/dateUtil';
import RoundedCard from './RoundedCard';
import { Subscription } from '../../types/subscriptions';

type ActiveSubscriptionCardProps = {
  subscription: Subscription;
}

export default function ActiveSubscriptionCard({ subscription }: ActiveSubscriptionCardProps): JSX.Element {
  const { t } = useTranslation('tickets_widget');

  return (
    <RoundedCard className="bg-sb-pink w-52 max-sm:w-full max-sm:flex max-sm:gap-2 max-sm:items-center">
      <QRCode value="value" className="w-full h-auto p-2 bg-white rounded-sb-sm max-sm:max-w-36" />
      <div>
        <p className="text-center max-sm:text-left text-white font-semibold mt-2">{subscription.subscriptionType.name}</p>
        <p className="text-center max-sm:text-left text-white">
          {subscription.subscriptionType.defaultRidesCount === 0
            ? t('unlimited')
            : `${subscription.subscriptionType.defaultRidesCount - subscription.rides}/${subscription.subscriptionType.defaultRidesCount} ${t('rides_left')}`}
        </p>
        <p className="text-center max-sm:text-left text-white">{t('expires_on')} {getDateString(subscription.expiry)}</p>
      </div>
    </RoundedCard>
  );
}
