import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DefaultLayout from '../components/layouts/DefaultLayout';
import NotFoundPage from '../pages/NotFoundPage';
import LandingPage from '../pages/LandingPage';
import AuthRoute from './AuthRoute';
import LoginPage from '../pages/auth/LoginPage';
import ResetPasswordPage from '../pages/auth/ResetPasswordPage';
import ForgottenPasswordPage from '../pages/auth/ForgottenPasswordPage';
import { SubscriptionBookPage } from '../pages/subscriptions/SubscriptionBookPage';
import PaymentPage from '../pages/PaymentPage';
import { SubscriptionExtensionPage } from '../pages/subscriptions/SubscriptionExtensionPage';
import { PaymentsPage } from '../pages/payment/PaymentsPage';
import { SubscriptionListPage } from '../pages/subscriptions/SubscriptionListPage';
import { TicketsListPage } from '../pages/tickets/TicketsListPage';

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        {/* Any auth */}
        <Route index element={<LandingPage />} />
        <Route path="/account/set-password" element={<ResetPasswordPage />} />

        {/* Authenticated */}
        <Route path="/" element={<AuthRoute.Authenticated />}>
          {/*  */}
          <Route path="/subscriptions-booking" element={<SubscriptionBookPage />} />
          <Route path='/payment' element={<PaymentPage/>}/>
          <Route path="/subscriptions-extend" element={<SubscriptionExtensionPage />} />
          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/subscriptions" element={<SubscriptionListPage />} />
          <Route path="/tickets" element={<TicketsListPage />} />
        </Route>

        {/* Anonymous */}
        <Route path="/" element={<AuthRoute.Anonymous />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
        </Route>
      </Route>
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}
