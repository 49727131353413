import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import RoundedCard from '../../components/cards/RoundedCard';
import ForgottenPasswordForm from '../../components/forms/auth/ForgottenPasswordForm';
import { requestPasswordReset } from '../../services/authService';

export default function ForgottenPasswordPage(): JSX.Element {
  const { t } = useTranslation('reset_password_forms');
  const [emailSent, setEmailSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (email: string): void => {
    setLoading(true);
    requestPasswordReset(email)
      .then(() => {
        toast.success(t('email_sent', { toastId: 'email_sent' }));
        setEmailSent(true);
      })
      .catch(() => {
        toast.error(t('email_failed', { toastId: 'email_failed' }));
        setEmailSent(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <RoundedCard variant="centered-form">
      <h2 className="text-2xl font-bold text-center">{t('password_recovery_title')}</h2>
      {emailSent
        ? <p className="text-center">{t('email_sent_message')}</p>
        : <ForgottenPasswordForm onSubmit={onSubmit} loading={loading} />
      }
    </RoundedCard>
  );
}
