import React, { HTMLAttributes } from 'react';
import RoundedCard from '../../cards/RoundedCard';

export function ProgrammeWidget(props: HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <RoundedCard {...props}>
      <h1>ProgrammeWidget</h1>
    </RoundedCard>
  );
}
