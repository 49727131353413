import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RoundedCard from "../RoundedCard";
import { setSubscriptionExtension } from '../../../redux/slices/subscriptionExtensionSlice';
import useSubscriptionExtensionState from '../../../hooks/selectors/useSubscriptionExtensionState';
import useSubscriptions from '../../../hooks/selectors/useSubscriptions';
import useDateFormatSubscriptionExtension from '../../../hooks/useDateFormatSubscriptionExtension';

type SubscriptionCardProps = {
  id: number;
}

export default function SubscriptionExtensionCard({
                                           id,
                                         }: SubscriptionCardProps): JSX.Element {
  const dispatch = useDispatch();
  const { subscriptionId } = useSubscriptionExtensionState();
  const  subscription = useSubscriptions().find(s => s.id === id);
  const { t } = useTranslation("subscription_extension_card");
  const format = useDateFormatSubscriptionExtension();
  if (!subscription) {
    return (<></>);
  }
  const handleOnClickSubscriptionCard = (): void => {
    if (subscriptionId === id) {
      return;
    }

    dispatch(setSubscriptionExtension({ subscriptionId: id, extensionTypeId: undefined, price: undefined }));
  }

  return (
    <RoundedCard
      onClick={handleOnClickSubscriptionCard}
      className={`flex justify-between items-center ${subscriptionId === id ? `bg-sb-dark-blue` : `bg-sb-purple`} 
      text-white rounded-sb-sm bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500 
      hover:cursor-pointer`}>
      <div>
        <p className="font-bold">{subscription.subscriptionType.name}</p>
        <p className="text-sm font-light">{subscription.subscriptionType.description}</p>
        <p className="text-sm font-light">{t("expires")} {format(subscription.expiry, 'dd-MM-yyyy')}</p>
        {
          subscription.subscriptionType.defaultRidesCount === 0 ?
            <p className="text-sm font-light">{t("rides")} {t('unlimited')}</p> :
            <p className="text-sm font-light">{t("rides")} {subscription.rides}</p>
        }
      </div>
    </RoundedCard>
  );
}
