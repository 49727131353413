import React, { HTMLAttributes, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import useSubscriptions from '../../../hooks/selectors/useSubscriptions';
import RoundedCard from '../../cards/RoundedCard';
import { getSubscriptions } from '../../../services/subscriptionService';
import { setSubscriptions } from '../../../redux/slices/subscriptionsSlice';
import SubscriptionListCard from '../../cards/SubscriptionListCard';

export function SubscriptionViewWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const dispatch = useDispatch();
  const subscriptions = useSubscriptions();
  const { t } = useTranslation('subscriptions_view_widget');

  useEffect(() => {
    getSubscriptions()
      .then((sbs) => dispatch(setSubscriptions(sbs)))
      .catch(() => toast.error(t('subscriptions_error')));
  }, []);

  return (
    <RoundedCard className={twMerge('flex flex-col', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        <div>
          <h2 className="text-xl font-bold py-2">{t('subscriptions')}</h2>
          <div className="flex flex-col gap-1">
            {subscriptions.map((subscription) => <SubscriptionListCard id={subscription.id}/>)}
          </div>
        </div>
      </div>
    </RoundedCard>
  );
}
