import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Link, Outlet } from 'react-router-dom';
import { TbUser, TbUserX } from 'react-icons/tb';
import { IoTicketOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import Logo from '../misc/Logo';
import LanguageMenu from '../buttons/LanguageMenu';
import { CircleIconButton } from '../buttons/CircleIconButton';
import Button from '../buttons/Button';
import useAuth from '../../hooks/selectors/useAuth';
import { logout } from '../../services/authService';

interface DefaultLayoutProps {
  children?: React.ReactNode;
  className?: string;
}

export default function DefaultLayout({
                                        children,
                                        className,
                                      }: DefaultLayoutProps): JSX.Element {
  const { t } = useTranslation('common');
  const auth = useAuth();

  return (
    <div className="bg-sb-dark-blue h-screen flex flex-col z-50">
      <div className="flex items-center justify-between h-[8.5rem] py-[2.5rem] px-24 max-md:px-8">
        <div className="flex items-center gap-8">
          <Link className="w-fit" to={!auth ? '/login' : '/'}>
            <Logo variant="white" className="w-32 max-md:w-16" />
          </Link>
          <div
            className="flex items-center justify-center gap-2 bg-black text-white font-semibold px-5 py-3 rounded-full max-sm:hidden">
            <p>{t('today').toLowerCase()}</p>
            <p>8:00-20:00</p>
          </div>
        </div>
        <div className="flex gap-4 items-center max-sm:gap-2">
          <LanguageMenu />
          <div className="flex items-center">
            <Menu>
              <MenuButton className="aspect-square bg-black text-white rounded-full p-3">
                {auth ? <TbUser /> : <TbUserX />}
              </MenuButton>
              <MenuItems transition anchor="bottom"
                         className="bg-black p-4 flex flex-col gap-2 z-[51] mt-4 w-52 origin-top-right rounded-xl transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0">
                <p className="text-white">{t('greeting')}</p>
                {auth
                  ? (
                    <>
                      <MenuItem>
                        <Link to="/account"><Button className="w-full bg-sb-purple">{t('account')}</Button></Link>
                      </MenuItem>
                      <MenuItem>
                        <Button onClick={logout} className="w-full bg-sb-purple">{t('logout')}</Button>
                      </MenuItem>
                    </>
                  )
                  : (
                    <>
                      <MenuItem>
                        <Link to="/login"><Button className="w-full bg-sb-purple">{t('login')}</Button></Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to="/forgotten-password"><Button className="w-full bg-sb-purple">{t('forgotten')}</Button></Link>
                      </MenuItem>
                    </>
                  )}
              </MenuItems>
            </Menu>
            <CircleIconButton icon={IoTicketOutline} className="bg-sb-pink text-xl -ml-1 hover:scale-110 hover:rotate-[30deg] transition-transform" />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          'min-h-[calc(100vh-8.5rem)] flex flex-1 flex-col gap-4 pb-6 lg:pb-24 max-w-[90vw] max-sm:max-w-[95vw] mx-auto w-full',
          className,
        )}>
        {children || <Outlet />}
      </div>
    </div>
  );
}
