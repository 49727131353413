import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/selectors/useAuth';

function Authenticated(): JSX.Element {
  const auth = useAuth();

  if (!auth) return <Navigate to="/login" />;
  return <Outlet />;
}

function Anonymous(): JSX.Element {
  const auth = useAuth();

  if (auth) return <Navigate to="/" />;
  return <Outlet />;
}

const AuthRoute = { Authenticated, Anonymous };

export default AuthRoute;
