import React, { HTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GiConfirmed } from 'react-icons/gi';
import { toast } from 'react-toastify';
import RoundedCard from '../../cards/RoundedCard';
import { AvailableSlot } from '../../../types/subscriptions';
import { createReservation, getReservableSlots } from '../../../services/subscriptionService';
import useSubscriptionBookingState from '../../../hooks/selectors/useSubscriptionBookingState';
import useAvailableDaysSubscriptionBooking from '../../../hooks/selectors/useAvailableDaysSubscriptionBooking';
import { isWithinDaySpan } from '../../../utils/dateUtil';
import SlotCard from '../../cards/SlotCard';
import { IconButton } from '../../buttons/IconButton';

export function SubscriptionBookingSlotWidget({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const [slots, setSlots] = useState<AvailableSlot[]>([]);
  const [slotId, setSlotId] = useState<number | undefined>(undefined);
  const { t } = useTranslation('slot_widget');
  const navigate = useNavigate();
  const { availableDays } = useAvailableDaysSubscriptionBooking();
  const { subscriptionId, cardholderIds, day } = useSubscriptionBookingState();

  useEffect(() => {
    getReservableSlots(subscriptionId, availableDays.find(availableDay => isWithinDaySpan(day, availableDay))?.id, cardholderIds)
      .then(availableSlots => {
        setSlots(availableSlots);
      });
  }, [day, cardholderIds]);

  const handleCreate = (): void => {
    if (!slotId) {
      return;
    }
    createReservation(subscriptionId, slotId, cardholderIds)
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        toast.error(t("no_rides_book"));
      })
  }

  return (
    <RoundedCard className={twMerge('flex flex-col max-h overflow-auto no-scrollbar', className)} {...props}>
      <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
        <div className="flex-1 flex flex-col justify-between max-sm:w-full max-h-[72vh] overflow-auto no-scrollbar">
          <div>
            <h2 className="text-xl font-bold py-2">{t('available_slots')}</h2>
            <div className="flex flex-col gap-1">
              {slots.map((slot) => <SlotCard slot={slot} selected={slotId === slot.id}
                                             onClick={() => setSlotId(slot.id)} />)}
            </div>
          </div>
        </div>
        {
          !!slotId &&
          <IconButton icon={GiConfirmed} label={t("confirm_reservation")} onClick={handleCreate}
                      className=" mt-3 bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
        }
      </div>
    </RoundedCard>
  );
}
