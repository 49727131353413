import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionBookingState } from '../../types/redux';

const initialState: SubscriptionBookingState = {
  subscriptionId: undefined,
  cardholderIds: [],
  day: new Date(),
};

const subscriptionBookingSlice = createSlice({
  name: 'subscriptionBooking',
  initialState,
  reducers: {
    setSubscriptionBooking(state, action: PayloadAction<SubscriptionBookingState>): void {
      state.subscriptionId = action.payload.subscriptionId;
      state.cardholderIds = action.payload.cardholderIds;
      state.day = action.payload.day;
    },
  },
});

const subscriptionBookingReducer = subscriptionBookingSlice.reducer;

export const { setSubscriptionBooking } = subscriptionBookingSlice.actions;
export default subscriptionBookingReducer;
