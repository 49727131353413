import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedCard from './RoundedCard';
import { AvailableSlot } from '../../types/subscriptions';
import useDateFormatSubscriptionBooking from '../../hooks/useDateFormatSubscriptionBooking';

type CardholderCardProps = {
  slot: AvailableSlot;
  selected: boolean;
  onClick: () => void;
}

export default function SlotCard({
                                         slot: {
                                           id: slotId,
                                           startTime,
                                           endTime,
                                           ticketsLeft
                                         },
                                         selected,
                                         onClick,
                                       }: CardholderCardProps): JSX.Element {
  const { t } = useTranslation('slot_card');
  const format = useDateFormatSubscriptionBooking();

  return (
    <RoundedCard onClick={onClick} key={`cardholder-${slotId}`}
                 className={`flex justify-between items-center bg-sb-purple text-white rounded-sb-sm 
                 bg-[position:_0%_0%] bg-[size:_200%] transition-all duration-500 
                 ${selected ? 'bg-sb-dark-blue' : 'drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'} hover:cursor-pointer`}>
      <div>
        <p className="font-light">{t("tickets_left")}{ticketsLeft}</p>
        <p className="font-light">{t("duration")}{format(startTime, 'hh:mm')} - {format(endTime, 'hh:mm')}</p>

      </div>
    </RoundedCard>
  );
}