import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Variant = 'inline-card' | 'centered-form';

type RoundedCardProps = {
  variant?: Variant;
} & HTMLAttributes<HTMLDivElement>;

const variantClasses: Record<Variant, string> = {
  'inline-card': '',
  'centered-form': 'w-full max-w-2xl max-sm:max-w-full mx-auto rounded-2xl py-16 px-8 flex flex-col gap-8 max-md:py-10 max-md:px-4',
};

export default function RoundedCard({ className, variant = 'inline-card', ...props }: RoundedCardProps): JSX.Element {
  return (
    <div className={twMerge('bg-white rounded-2xl p-4', variantClasses[variant], className)} {...props} />
  );
}
